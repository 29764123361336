// react imports
import React, { useState } from "react";

// prime react components
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

// styles
import "./ActivitiesFilterPanel.scss";

// const
import { INSTITUTIONS, IMPLEMENTATION_STATUSES } from "../../../apis/sampleData";


const ActivitiesFilterPanel = () => {
  // states
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const [selectedImplementationStatus, setSelectedImplementationStatus] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  // events
  const clickOnReset = () => {
    setSelectedInstitution([]);
    setSelectedImplementationStatus('');
    setSearchKeyword('');
  };

  return (
    <div className="filter-panel activities-filter-panel">
      <div className="p-fluid p-formgrid p-grid filter-row-1">
        <div className="p-field p-col select-col">
          <label htmlFor="lead-institution">Lead institution</label>
          <MultiSelect
            id="lead-institution"
            display="chip"
            value={selectedInstitution}
            options={INSTITUTIONS}
            optionLabel="name"
            optionValue="id"
            onChange={(e) => setSelectedInstitution(e.value)}
            className="institution-select"
            maxSelectedLabels="1"
            placeholder="Select"
          />
        </div>
        <div className="p-field p-col select-col">
          <label htmlFor="sdgs">SDGs</label>
          <Dropdown
            id="sdgs"
            display="chip"
            value={selectedImplementationStatus}
            options={IMPLEMENTATION_STATUSES}
            onChange={(e) => setSelectedImplementationStatus(e.value)}
            placeholder="Select"
          />
        </div>
        <div className="p-field p-col keyword-col">
          <InputText placeholder="Type keyword" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
        </div>
      </div>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col button-col">
          <a className="reset-link" onClick={clickOnReset}>
            Reset
          </a>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesFilterPanel;