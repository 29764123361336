// primereact
import { Dropdown } from 'primereact/dropdown';

// style
import "./FilterSelect.scss";


/**
 * Props properties:
 * @param title: string
 * @param iconClass: string
 * @param options: array of strings
 * @param value: string
 * @param onSelectValue: callback: selectedValue => {}
 */
const FilterSelect = props => {
  return (
    <div className="nrtd-filter-select">
      <i className={props.iconClass}></i>

      {props.title}

      <Dropdown
        value={props.value}
        options={props.options}
        onChange={(e) => props.onSelectValue(e.value)}
        panelClassName="filter-select-panel"
      />
    </div>
  )
};

export default FilterSelect;
