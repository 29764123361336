export const truncate = (s, length) => {
  return (s.length > length) ?
    (s.substring(0, length - 3) + '...') :
    s;
}

export const onlyUniqueValues = arr => arr.filter((val, i) => {
  return arr.indexOf(val) === i;
});

export const hexToRGBA = (hex, opacity) => {
  var c;

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }

      c= '0x'+c.join('');

      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',') + `, ${opacity})`;
  }

  throw new Error('Bad Hex');
};

export const stripHTMLTags = html => {
  let commentWrapperElement = document.createElement("div");
  commentWrapperElement.innerHTML = html;

  return commentWrapperElement.textContent || commentWrapperElement.innerText || "";
};
