// react imports
import React, { useEffect, useState } from "react";

// primereact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';


/**
 * Properties:
 * @param dialogVisible: bool
 * @param dialogHeader: string
 * @param onClose: () => {}
 * @param onSave: (value) => {}
 * @param saveButtonLabel: string (default: Save)
 */
const AttachmentDialog = (props) => {

  // events
  const saveClickHandler = () => {
    props.onSave();
  };
  const cancelClickHandler = () => {
    props.onClose();
  };

  // templates
  const footerTemplate = (
    <>
      <Button label={props.saveButtonLabel ? props.saveButtonLabel : "Save"} onClick={saveClickHandler} />
      <Button label="Cancel" onClick={cancelClickHandler} className="p-button-secondary" />
    </>
  );

  return (
    <Dialog
      header={props.dialogHeader}
      visible={props.dialogVisible}
      style={{ width: '460px' }}
      onHide={() => props.onClose()}
      footer={footerTemplate}
      dismissableMask={true}
    >
      <div className="nrtd--dialog-form">
        <div className="p-fluid">
          < div className="p-field">
            <FileUpload
              url="./upload"
              mode="basic"
              chooseLabel=""
              uploadLabel=""
              cancelLabel=""
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AttachmentDialog;